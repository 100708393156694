<template>
    <div style="padding-bottom: 50px">
        <HomeCenterRows v-for="item in homeRows" :more="item">{{item}}</HomeCenterRows>
    </div>
</template>

<script>
    import HomeCenterRows from './HomeCenterRows'
    import { homeRows } from '@/utils/ApiConstant'
    import {inject, reactive, watch} from "vue";
    export default {
        name: "HomeCenterRowList",
        components:{
            HomeCenterRows,
        },
        setup() {
            const moolngHomePageData = inject('moolngHomePageData')
            console.log('home center row list moolngHomePageData ===>', moolngHomePageData)
            watch(()=>{moolngHomePageData.homeRowData},(o, n)=>{
                console.log('home center row list watch ===>', moolngHomePageData.homeRowData)
            })
            let data = reactive({
                rowList:[]
            })

            // watch(()=>moolngHomePageData.navData,(n, o)=>{
            //     let navMap = moolngHomePageData.navData.navMap
            //     console.log('moolngHeaderMenu get homePageData nav data', navMap)
            //     if(navMap) {
            //         data.rowMap = navMap
            //     }
            // })

            return {
                data,
                homeRows,
            }
        }
    }
</script>

<style scoped>
</style>