<template>
<!--        <BackTop/>-->
        <article id="detail-article">
<!--            {{data.info}}-->
            <header class="title" id="name">
                <h1>{{data.info.name}}<span id="pinyin">({{data.info.pinyin}})</span></h1>
            </header>
            <section class="option">
                <div>
                    <Tabs v-model:activeKey="activeKey" class="lzy-tab" destroyInactiveTabPane="true">
                        <TabPane key="1" tab="图片">
                            <Row :gutter="16" id="picture">
                                <Col :span="8">
                                    <Card title="原生态图片" :bordered="false">
                                        <p v-if="data.info.originalEcologicalPic === ''"><SkeletonImage/></p>
                                        <p v-if="data.info.originalEcologicalPic != ''"><img :src="data.info.originalEcologicalPic" style="width: 200px"/></p>
                                    </Card>
                                </Col>
                                <Col :span="8">
                                    <Card title="未加工图片" :bordered="false">
                                        <p v-if="data.info.rawPic === ''"><SkeletonImage/></p>
                                        <p v-if="data.info.rawPic != ''"><img :src="data.info.rawPic" style="width: 200px"/></p>
                                    </Card>
                                </Col>
                                <Col :span="8">
                                    <Card title="已加工图片" :bordered="false">
                                        <p v-if="data.info.processedPic === ''"><SkeletonImage/></p>
                                        <p v-if="data.info.processedPic != ''"><img :src="data.info.processedPic" style="width: 200px"/></p>
                                    </Card>
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane key="2" tab="视频介绍">
                            <iframe v-if="data.info.physicalCommentaryVideo != ''"
                                    width="700"
                                    height="500"
                                    :src="data.info.physicalCommentaryVideo"
                                    scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="true">
                            </iframe>
                            <span v-else style="text-align: center;font-size: 30px">视频数据维护中...</span>
                        </TabPane>
                        <TabPane key="3" :tab="`倪师讲解【视频起始时间位置：(${data.info.explainVideoTime})】`" id="physicalExplainVideo">
<!--                            :src="data.info.physicalExplainVideo"-->
<!--                            //player.bilibili.com/player.html?aid=517282420&bvid=BV1xg411B7U8&cid=881990652&page=5&t=969-->
                            <iframe v-if="data.info.physicalExplainVideo != ''"
                                    width="700"
                                    height="500"
                                    :src="`${data.info.physicalExplainVideo}&t=${data.info.explainVideoTimeSize}`"
                                    scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="true">
                            </iframe>
                            <span v-else style="text-align: center;font-size: 30px">视频数据维护中...</span>
                        </TabPane>
                    </Tabs>
                    <card title="【别名】" :bordered="false" id="bieMing">
                        <p>{{data.info.alias}}</p>
                    </card>
                    <card title="【本经原文】" :bordered="false" id="originalText">
                        <p>{{data.info.originalText}}</p>
                    </card>
                    <card title="【产地】" :bordered="false" id="origin">
                        <p>{{data.info.origin}}</p>
                    </card>
                    <card title="【性味】" :bordered="false" id="natureTaste">
                        <p>{{data.info.natureTaste}}</p>
                    </card>
                    <card title="【主治】" :bordered="false" id="indications">
                        <p>{{data.info.indications}}</p>
                    </card>
                    <card title="摘要" :bordered="false" id="digest">
                        <p v-html="data.info.digest"/>
                    </card>
                    <card title="【用量】" :bordered="false" id="dosage">
                        <p v-html="data.info.dosage"/>
                    </card>
                    <card title="【禁忌】" :bordered="false" id="contraindication">
                        <p v-html="data.info.contraindication"/>
                    </card>
                    <card title="【炮制】" :bordered="false" id="processing">
                        <p v-html="data.info.processing"/>
                    </card>
                    <card title="【附方】" :bordered="false" id="supplementaryFormula">
                        <p v-html="data.info.supplementaryFormula"/>
                    </card>
                </div>
            </section>
        </article>
</template>

<script>
    import { Card,Row,Col,SkeletonImage, Tabs, TabPane } from 'ant-design-vue'
    import { onMounted, reactive, ref, inject } from 'vue'
    import { get } from '@/utils/HttpClient'
    import { useRoute, useRouter } from 'vue-router'

    export default {
        name: "DetailArticle",
        components:{
            Card,
            Row,
            Col,
            SkeletonImage,
            Tabs,
            TabPane,
            // BackTop
        },
        // emits:['click'],
        setup(props, context){
            const moolngHomePageData = inject('moolngHomePageData')
            let data = reactive({
                headStyle:{
                    'display': 'block',
                    'font-size': '2em',
                    'margin-block-start': '0.67em',
                    'margin-block-end': '0.67em',
                    'margin-inline-start': '0px',
                    'margin-inline-end': '0px',
                    'font-weight': 'bold'
                },
                info:{}
            })
            let route = useRoute()
            let router = useRouter()
            onMounted(()=>{
                let strId = route.params.strId
                get(`/web/medicine/view/${strId}`).then(res=>{
                    // get('/getMenu').then(res=>{
                    console.log('DetailArticle.vue res ', res)
                    if(res.status === 200) {
                        data.info = res.result
                        console.info(`/web/medicine/view/${strId}`, data.info)
                        setDetailName(data.info.name)
                        setHeadElement(data.info)
                    }else if(res.status === '404') {
                        // 404
                        router.push('/detail404')
                    }
                })
            })

            function setHeadElement(info) {
                setTitle(info)
                setKeywords(info)
                setDescription(info)
            }

            function setTitle(info) {
                document.title = process.env.VUE_APP_DEV_WEB_TITLE + '-' + '中药材、' + info.name + '、' + (info.alias ? info.alias : '别名') +'、倪海厦、倪师'
            }

            function setKeywords(info) {
                // 获取原始
                // let meta = document.getElementsByName('keywords')[0]
                let meta = document.getElementsByTagName('head')[0].getElementsByTagName('keywords')[0]
                // console.log('meta', meta)
                if(meta === undefined) {
                    meta = document.createElement('meta');
                    meta.name='keywords';
                    document.getElementsByTagName('head')[0].appendChild(meta);
                    meta.content = info.name + '、医案、倪海厦、倪师'
                }
            }

            function setDescription(info) {
                // 获取原始
                let meta = document.getElementsByTagName('head')[0].getElementsByTagName('description')[0]
                if(meta === undefined) {
                    meta = document.createElement('meta');
                    meta.name='description';
                    document.getElementsByTagName('head')[0].appendChild(meta);
                    meta.content = info.visitReasonText
                }
            }

            function setDetailName(detailName) {
                moolngHomePageData.currentCategoryObj.detailName = detailName
            }

            return {
                data,
                // show,
                // closeVideo,
                activeKey: ref('1'),
            }
        }
    }
</script>

<style scoped>
    #detail-article {
        /*padding: 0 20px 0 0;*/
        min-height: 280px;
    }
    .ant-skeleton-element .ant-skeleton-image {
        width:200px
    }
    .title {
        /*font-size: 30px;*/
    }
    #pinyin {
        font-size: 20px;
    }
    .lzy-tab {
        padding-left: 30px;
    }
    h1 {
        display: block;
        font-size: 2em;
        margin-block-start: 0.67em;
        margin-block-end: 0.67em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
        margin-left: 30px;
    }
</style>
