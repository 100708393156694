<template>
    <router-view></router-view>
</template>

<script>


export default {
  name: 'App',
  components: {
  },
    setup() {
      return {
      }
    }
}
</script>

<style>
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
} */
  #app {

  }

    .home-width {
        width: 1000px;
        margin: 0 auto;
    }
</style>
