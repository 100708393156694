<template>
    <div class="error503">
        <img :src="error503png">
    </div>
</template>

<script>
    export default {
        name: "Detail503",
        setup(){
            return {
                error503png: require("@/assets/503.png"),
            }
        }
    }
</script>

<style scoped>
    .error503 {
        text-align: center;
        font-size: 40px;
        height: 500px;
        background-color: #FBFBFB;
        /*height: 100%;*/
        /*width: 100%;*/
        /*position: fixed;*/
        /*display: none;*/
    }
    .error503 img {
        margin-top: 100px;
        /*display: block;*/
        /*position: absolute;*/
        top: 50px;
        left: 50%;
    }
</style>