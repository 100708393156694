<template>
    <Contextmenu ref="contextmenu">
        <ContextmenuItem class="copy" :data-clipboard-text="moolngDefaultDetailData.editText" @click="doCopy"><CopyFilled />&nbsp;复制</ContextmenuItem>
        <ContextmenuDivider />
        <ContextmenuItem @click="onSelect2"><EditFilled />&nbsp;纠错</ContextmenuItem>
    </Contextmenu>
    <div v-contextmenu:contextmenu>
        <slot></slot>
    </div>

<!--    <Modal v-model:visible="data.visible" title="Title" @ok="handleOk">-->
    <Modal v-model:visible="data.visible" title="纠错-功能开发中敬请期待！！！" @ok="handleOk">
        <template #footer>
            <Button key="back" @click="handleCancel">关闭</Button>
            <Button key="submit" type="primary" :loading="data.loading" @click="handleOk">提交</Button>
        </template>
        {{moolngDefaultDetailData.editText}}
    </Modal>

</template>

<script>
    import Clipboard from 'clipboard'
    import { CopyFilled, EditFilled } from '@ant-design/icons-vue';
    import { Modal, Button, notification } from 'ant-design-vue'
    import { reactive, inject } from 'vue'
    import {directive, Contextmenu, ContextmenuItem, ContextmenuDivider } from "v-contextmenu";
    import "v-contextmenu/dist/themes/default.css";

    export default {
        name: "MoolngContextMenu",
        directives: {
            contextmenu: directive,
        },
        components:{
            Contextmenu,
            ContextmenuItem,
            ContextmenuDivider,
            Modal,
            Button,
            CopyFilled,
            EditFilled,
        },
        emits:['selectText'],
        setup(props, context){
            const  moolngDefaultDetailData = inject('moolngDefaultDetailData')
            console.log('moolngDefaultDetailData ===> ', moolngDefaultDetailData)
            const data = reactive({
                loading:false,
                visible:false,
                editText:'',
            })

            function onSelect2() {
                if(moolngDefaultDetailData.editText === '') {
                    notification.warning({
                        message: '温馨提示',
                        description: '请选择你要纠错的内容',
                        onClick: () => {

                        },
                    });
                    return
                }
                showModal()
            }

            const doCopy = () => {
                let clipboard = new Clipboard('.copy')
                console.log('doCopy===>', clipboard)
                clipboard.on('success', (e) => {
                    console.log('复制成功', e)
                    // 释放内存
                    clipboard.destroy()
                    // alert("复制成功！");
                })
                clipboard.on('error', (e) => {
                    // 不支持复制
                    console.log('该浏览器不支持自动复制', e)
                    // 释放内存
                    clipboard.destroy()
                    alert("该浏览器不支持自动复制,请手动复制！");
                })
            }

            const showModal = () => {
                data.visible = true;
            };
            const handleOk = () => {
                data.loading = true;
                setTimeout(() => {
                    data.loading = false;
                    data.visible = false;
                }, 2000);
            };
            const handleCancel = () => {
                data.visible = false;
            };

            return {
                data,
                onSelect2,
                doCopy,
                showModal,
                handleOk,
                handleCancel,
                moolngDefaultDetailData,
            }
        }
    }
</script>

<style scoped>

</style>