<template>
    <div class="setting-drawer">
        <Drawer title="中医交流" width="200" :visible="visible" @close="onClose" :drawer-style="{ position: 'absolute' }">
            <span>
                <a target="_blank" href="https://qm.qq.com/cgi-bin/qm/qr?k=HEPUgOSVNYQmVMRdRXUIBjSgUS455Kga&jump_from=webapi&authKey=XlwB0HlejdkG9UtOtKqO2mlKceMdksp793GTE8J2ge97MYPzd/pI8NzU9vzDS35o"><img border="0" src="//pub.idqqimg.com/wpa/images/group.png" alt="论中医技术交流群" title="论中医技术交流群"></a>
            </span>
            <br/>
            <br/>
            <span>QQ扫描进群</span>
            <br/>
            <br/>
            <span>
                <img :src="QQ29595458" style="width: 150px; height: 150px">
            </span>
        </Drawer>
<!--        <div class="setting-drawer-index-handle" @click="toggle" slot="handle">-->
        <div class="setting-drawer-index-handle" @click="showDrawer()" v-if="!visible">
            <span id="jlq">交流群</span>
<!--            <QqOutlined :style="{fontSize: '30px', color: '#fff'}"/>-->
        </div>
    </div>


</template>

<script>
    import { ref } from 'vue'
    import { Drawer, } from 'ant-design-vue'
    import { QqOutlined } from '@ant-design/icons-vue';
    export default {
        name: "RightBuoy2",
        components:{
            Drawer,
            QqOutlined,
        },
        setup() {

            const visible = ref(false);
            // const size = ref('default');
            const showDrawer = () => {
                // size.value = val;
                visible.value = true;
            };
            const onClose = () => {
                visible.value = false;
            };
            return {
                visible,
                // size,
                showDrawer,
                onClose,
                QQ29595458: require("@/assets/QQ29595458.png"),
            }
        },
    };
</script>
<style lang="less" scoped>
    .setting-drawer {
        position: fixed;
        top: 0;
        right: 0;
        /*height: 100%;*/
        /*height: 0px;*/
        /*width: 0px;*/
        /*background-color: #fff;*/
        /*transition: all 0.2s ease-in-out;*/
        /*box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);*/
        z-index: 9999;
    }
    #jlq {
        padding: 5px 5px 5px 5px;
    }
    .setting-drawer-index-handle {
        position: absolute;
        /*top: 240px;*/
        top: 200px;
        right: 0px;
        background: #1890ff;
        width: 25px;
        color: white;
        /*box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);*/
        /*width: 48px;*/
        /*height: 48px;*/
        /*right: 300px;*/
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        pointer-events: auto;
        z-index: 1001;
        text-align: center;
        font-size: 15px;
        border-radius: 8px 0 0 8px;

        /*i {*/
        /*    color: rgb(255, 255, 255);*/
        /*    font-size: 20px;*/
        /*}*/
    }
</style>