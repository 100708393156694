<template>
    <HomeCenterRowList></HomeCenterRowList>
</template>

<script>
    import HomeCenterRowList from '@/components/home/HomeCenterRowList'

    export default {
        name: "HomeCenter",
        components:{
            HomeCenterRowList,
        },
        setup(){
            return {

            }
        }
    }
</script>

<style scoped>

</style>