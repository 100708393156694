<template>
    <div class="lzy-footer">
        <span class="lzy-warning">身体不适时请至正规医院就诊！勿延误！</span><br/>
        <span class="lzy-warning">站内信息时效及准确性不足！仅作爱好者休闲。</span><br/>
        <span class="lzy-warning">本站是个人因爱好而建，非医药行业，不看病、不卖药、不授课、不参与任何商业！</span><br/>
        <br/>
        <br/>
        Copyright © 2022-2023 论中医<br/>
        <a href="https://beian.miit.gov.cn/">{{info.beianhao}}</a><br/>
        声明: 本网站部分内容从互联网收集整理，如果您发现不合适的内容，请联系我们进行处理，谢谢合作！<br/>

    </div>
</template>

<script>
    import { reactive } from 'vue'
    export default {
        name: "MoolngFooter",
        setup() {
            let info = reactive({
                'beianhao':'京ICP备12014686号-3'
            })
            return {
                info
            }
        }
    }
</script>

<style scoped>
    .lzy-footer {
        background-color : #f0f2f5;
        text-align: center;
        padding: 30px 0 30px 0;
    }
    .lzy-warning {
        color: red;
        font-weight: bold;
        font-size: 20px;
    }
</style>