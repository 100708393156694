<template>
    <Menu mode="horizontal" theme="dark" :style="{ lineHeight: '64px', fontSize:'18px'}" selectable="false">
        <template v-for="item in data.menuList" :key="item.strId">
            <template v-if="item.children.length === 0">
                <MenuItem :key="item.strId" @click="menuClick(item)">
                    {{item.name}}
                </MenuItem>
            </template>
            <template v-else>
                <MenuChildren :menuInfo="item" @childrenMenuClick="menuClick"/>
            </template>
        </template>
    </Menu>
</template>

<script>
    import {reactive, inject, watch} from 'vue'
    import { Menu, MenuItem } from 'ant-design-vue'
    import { useRouter, useRoute } from 'vue-router'
    import MenuChildren from './MenuChildren'

    import { post } from '@/utils/HttpClient'

    export default {
        name: "MoolngHeaderMenu",
        components: {
            Menu,
            MenuItem,
            MenuChildren,
        },
        emits:['setCategoryName', 'setRowData'],
        setup(props, context) {
            const router = useRouter()
            const route = useRoute()

            const moolngHomePageData = inject('moolngHomePageData')
            let data = reactive({
                menuList: [],
            })

            function menuClick(item) {
                console.log('menuClick', item)
                let routePath = item.routePath
                let dataPath = item.dataPath
                let title = item.name
                data.dataPath = dataPath
                if(!routePath) {
                    routePath = "/index"
                }
                let currentRoutePath = route.path
                if(currentRoutePath === '/index') {
                    moolngHomePageData.centerBackgroundColor = 'white'
                }else {
                    moolngHomePageData.centerBackgroundColor = '#f0f2f5'
                }
                let pattern = /^\/list\/\w[-\w.+]*/
                // console.log(pattern.test(currentRoutePath));

                // if(currentRoutePath === '/list') {
                if(pattern.test(currentRoutePath) || routePath === '/list') {
                    // TODO 存在BUG,重复请求查询结果
                    // const obj = {dataPath:dataPath, categoryStrId:item.strId}
                    // getRowData(obj)
                }
                if(routePath === '/list') {
                    routePath = routePath  + "/" + item.strId
                }
                setCurrentCategoryInfo(title, routePath)
                router.push(routePath)
            }

            // function getRowData(dataPath, pageSize, pageNo) {
            function getRowData(obj) {
                if(obj.dataPath === '') {
                    return
                }
                post(obj.dataPath, {...obj}).then(res=>{
                    if(res.status === 200) {
                        moolngHomePageData.pageListResultData = res.result
                    }
                })
            }

            function setCurrentCategoryInfo(categoryName, routePath) {
                moolngHomePageData.currentCategoryObj.name = categoryName
                moolngHomePageData.currentCategoryObj.routePath = routePath
            }
            // 监听导航数据变化进行数据渲染
            watch(() => moolngHomePageData.navData, (n, o) =>{
                let dataList = moolngHomePageData.navData.navMenuVOList
                if(dataList) {
                    data.menuList = dataList
                }
            })

            watch(()=>route.path,(n, o)=>{
                let currentRoutePath = route.path
                console.log('watch route.path===>', currentRoutePath)
                let pattern = /^\/list\/\w[-\w.+]*/
                if(pattern.test(currentRoutePath)) {
                    let strId = route.params.strId
                    let navObj = moolngHomePageData.navData.navMap[strId]
                    console.log('watch route.path strId, navObj ===> ', strId, navObj)
                    let datePath = navObj.dataPath
                    if(datePath === undefined) {
                        return
                    }
                    const obj = {dataPath:datePath, categoryStrId:strId}
                    // 暂且停用 2023-4-27 22:03:10
                    // if(moolngHomePageData.currentRouteInfo.flag) {
                    //     console.log('watch route.path moolngHomePageData.currentRouteInfo ===》', moolngHomePageData.currentRouteInfo)
                    //     let pageSize = moolngHomePageData.currentRouteInfo.pageSize
                    //     let pageNo = moolngHomePageData.currentRouteInfo.pageNo
                    //     obj.pageSize = pageSize
                    //     obj.pageNo = pageNo
                    //     moolngHomePageData.currentRouteInfo.pageSize = 1
                    //     moolngHomePageData.currentRouteInfo.pageNo = 10
                    //     moolngHomePageData.currentRouteInfo.flag = false
                    // }
                    getRowData(obj)
                }

            })

            return {
                data,
                menuClick,
            }
        }
    }

</script>

<style scoped>

</style>