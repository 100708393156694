<template>
    <Anchor mode="left" @click="goAnchor">
        <AnchorLink
                v-for="item in data.anchorList"
                :key="item.id"
                :href="`#${item.id}`"
                :title="item.title"
        ></AnchorLink>
    </Anchor>

</template>

<script>
    import { reactive } from 'vue'
    import { Anchor, AnchorLink } from 'ant-design-vue'

    export default {
        name: "DetailAnchor",
        components:{
            Anchor,
            AnchorLink,
        },
        setup() {
            let data = reactive({
                anchorList:[
                    {id:'name', title:'名称'},
                    {id:'bieMing', title:'别名'},
                    {id:'originalText', title:'本经原文'},
                    {id:'origin', title:'产地'},
                    {id:'natureTaste', title:'性味'},
                    {id:'digest', title:'摘要'},
                    {id:'picture', title:'图片'},
                ]
            })
            // 此方法有2个参数 e==>PointerEvent, link==>{title: '用法与用量', href: '#yongliang'}
            function goAnchor(e) {
                e.preventDefault()
                // 实际测试发现，调用e.preventDefault()之后，只会阻止URL被修改，并不会阻止点击锚点后的滚动事件。这样的话，那句scrollIntoView就可以不用了。
                // if(link.href) {
                //     let id = document.getElementById(link.href)
                //     id && id.scrollIntoView({block: 'start', behavior: 'smoot'})
                // }
            }
            return {
                data,
                goAnchor,
            }
        }
    }
</script>

<style scoped>

</style>