<template>
    <div class="error404">
        非法操作
<!--        <img :src="error404png">-->
<!--        <Button type="dashed" size="large" id="returnIndexBut" @click="goHome">返回首页</Button>-->
    </div>
</template>

<script>
    import { Button } from 'ant-design-vue'
    import { useRouter } from 'vue-router'
    export default {
        name: "Error503",
        components:{
            Button
        },
        setup(){
            const router = useRouter()
            function goHome() {
                router.push('/index')
            }
            return {
                error404png: require("@/assets/404.png"),
                goHome
            }
        }
    }
</script>

<style scoped>
    .error404 #returnIndexBut {
        display: block;
        position: absolute;
        top: 520px;
        left: 50%;
        /*background-color: coral;*/
    }
    .error404 {
        /*text-align: center;*/
        /*font-size: 40px;*/
        /*height: 500px;*/
        background-color: #FBFBFB;
        height: 100%;
        width: 100%;
        /*position: fixed;*/
        /*display: none;*/
    }
    .error404 img {
        /*margin-top: 100px;*/
        display: block;
        position: absolute;
        top: 20%;
        left: 35%;
    }
</style>