<template>
<!--    <h1>{{data}}</h1>-->
    <Breadcrumb style="margin: 16px 0">
        <BreadcrumbItem>首页</BreadcrumbItem>
        <BreadcrumbItem v-if="moolngHomePageData.currentCategoryObj.name != ''"><router-link :to="moolngHomePageData.currentCategoryObj.routePath">{{moolngHomePageData.currentCategoryObj.name}}</router-link></BreadcrumbItem>
        <BreadcrumbItem v-if="moolngHomePageData.currentCategoryObj.detailName != ''">{{moolngHomePageData.currentCategoryObj.detailName}}</BreadcrumbItem>
    </Breadcrumb>
    <Layout style="padding: 24px 0; background: #fff">
        <template v-if="categoryType === 'medicine'">
            <LayoutContent>
                <MedicineDetailContent></MedicineDetailContent>
            </LayoutContent>
        </template>
        <template v-else-if="categoryType === 'consilia'">
            <LayoutContent>
                <ConsiliaDetailArticle></ConsiliaDetailArticle>
            </LayoutContent>
        </template>
        <template v-else>
            <LayoutContent>
                <DefaultDetailContent></DefaultDetailContent>
            </LayoutContent>
        </template>
    </Layout>


</template>

<script>
    import { Layout, LayoutSider, LayoutContent, Breadcrumb, BreadcrumbItem } from 'ant-design-vue'

    import DetailAnchor from '@/components/detail/DetailAnchor'
    import DetailArticle from '@/components/detail/DetailArticle'
    import ConsiliaDetailArticle from '@/components/detail/ConsiliaDetailArticle'
    import DefaultDetailContent from '@/components/detail/DefaultDetailContent'
    import MedicineDetailContent from '@/components/detail/MedicineDetailContent'
    import {reactive, inject, onBeforeMount, computed} from "vue";
    import { useRoute, useRouter } from 'vue-router'

    export default {
        name: "DetailContent",
        components:{
            Layout,
            LayoutSider,
            LayoutContent,
            Breadcrumb,
            BreadcrumbItem,
            DetailAnchor,
            DetailArticle,
            ConsiliaDetailArticle,
            DefaultDetailContent,
            MedicineDetailContent,
        },
        setup(){
            // 参数获取 route.params
            const route = useRoute()
            const router = useRouter()
            const moolngHomePageData = inject('moolngHomePageData')

            // console.info('route', route.params)
            // console.info('router', router.params)
            const data = reactive({
                detailName:'',
                categoryName:'',
            })

            let categoryType = computed(()=>{
                return  route.params.category
            })

            return {
                data,
                categoryType,
                moolngHomePageData,
            }
        }
    }
</script>

<style scoped>
    .detail-left {
        width: 200px;
        background: white;
        padding: 0px 0px 0px 20px;
    }
</style>