<template>
    <Row class="my-row">
        <Col :span="24">
            <Row>
                <Col :span="23">
                    <div class="gutter-box">
                        <h3>{{more.title}}</h3>
                    </div>
                </Col>
                <Col :span="1">
                    <div class="gutter-box">
                        <h3><a @click.prevent="moreBut(more)">更多</a></h3>
                    </div>
                </Col>
            </Row>
            <Row :gutter="16">
                <Col :span="12">
                    <div class="gutter-box">
                        <List size="small" bordered :data-source="data.rowDataList[0]">
                            <template #renderItem="{ item }">
                                <ListItem>
                                    <a @click.prevent="gotoDetail(item.strId, more)" >{{ item.title }}</a>
                                </ListItem>
                            </template>
                        </List>
                    </div>
                </Col>
                <Col :span="12">
                    <div class="gutter-box">
                        <List size="small" bordered :data-source="data.rowDataList[1]">
                            <template #renderItem="{ item }">
                                <ListItem>
                                    <a @click.prevent="gotoDetail(item.strId, more)">{{ item.title }}</a>
                                </ListItem>
                            </template>
                        </List>
                    </div>
                </Col>
            </Row>
        </Col>
    </Row>

</template>

<script>
    import {reactive, onBeforeMount, inject, watch} from 'vue'
    import { List, ListItem, Row, Col } from 'ant-design-vue'
    import { post } from '@/utils/HttpClient'
    import { useRouter } from 'vue-router'
    import { constant } from '@/common/Common'

    export default {
        name: "HomeCenterRows",
        components:{
            List,
            ListItem,
            Row,
            Col,
        },
        props:{
            dataUrl: String,
            category: String,
            title: String,
            more: Object
        },
        setup(props) {
            const router = useRouter()
            const moolngHomePageData = inject('moolngHomePageData')
            const data = reactive({
                rowDataList:[],
                category: '',
                iterationFlag : true,
            })
            // 需要优化 onBeforeMount 和 watch 有可能冲突
            onBeforeMount(()=>{
                console.log('homecontentrows onBeforeMount ===>', moolngHomePageData.homeRowData)
                let rowList = moolngHomePageData.homeRowData[props.more.strId]
                if(rowList !== undefined && data.iterationFlag) {
                    data.iterationFlag = false
                    getData(rowList)
                }
            })

            watch(()=>moolngHomePageData.homeRowData, (o, n)=>{
                console.log('homecontentrows watch srtId ===>',props.more.strId, moolngHomePageData.homeRowData)
                let rowList = moolngHomePageData.homeRowData[props.more.strId]
                if(rowList !== undefined && data.iterationFlag) {
                    data.iterationFlag = false
                    getData(rowList)
                }
            })

            function gotoDetail(strId, more) {
                setBreadcrumbInfo(more)
                const routerLink = constant.routerLinkMap[more.category] + strId
                console.info('goto routerLink==>', routerLink)
                router.push(routerLink)
            }

            function getData(rowList) {
                // console.log('rowList ===>', rowList)
                let length = rowList.length;
                let rowDataOne = [];
                let rowDataTwo = [];
                if(length) {
                    let size = length / 2;
                    // console.info(size)
                    // console.info("@@",length / 2)
                    // console.info("##",length % 2)
                    if(length / 2 > 0 && length % 2 === 0) {
                        let i = 0;
                        for(let [index, item] of rowList.entries()) {
                            if(index < size) {
                                rowDataOne[i] = item;
                            }else {
                                if(i == index) {
                                    i = 0;
                                }
                                rowDataTwo[i] = item;
                            }
                            i++;
                        }
                        data.rowDataList.push(rowDataOne)
                        data.rowDataList.push(rowDataTwo)
                    }else {
                        let i = 0;
                        for(let [index, item] of rowList.entries()) {
                            if(index < size) {
                                rowDataOne[i] = item;
                            }else {
                                if(i == index) {
                                    i = 0;
                                }
                                rowDataTwo[i] = item;
                            }
                        }
                        data.rowDataList.push(rowDataOne)
                        data.rowDataList.push(rowDataTwo)
                    }
                }
            }


            function setBreadcrumbInfo(more) {
                let nav = moolngHomePageData.navData.navMap[more.strId]
                moolngHomePageData.currentCategoryObj.name = nav.name
                moolngHomePageData.currentCategoryObj.routePath = nav.routePath + "/" + nav.strId
            }

            function moreBut(more) {
                setBreadcrumbInfo(more)
                getRowData(more.pageDataPath)
                router.push(more.morePath)
            }

            function getRowData(dataPath, pageSize, pageNo) {
                post(dataPath, { pageSize:pageSize, pageNo:pageNo }).then(res=>{
                    if(res.status === 200) {
                        moolngHomePageData.pageListResultData = res.result
                    }
                })
            }

            return {
                data,
                gotoDetail,
                moreBut,
            }
        }
    }
</script>

<style scoped>
    .my-row {
        padding-top: 10px;
    }
</style>