<template>
    <Layout style="padding: 24px 0; background: #fff">
        <LayoutContent>
            <DetailArticle></DetailArticle>
        </LayoutContent>
        <LayoutSider class="detail-left">
            <DetailAnchor></DetailAnchor>
        </LayoutSider>
    </Layout>


</template>

<script>
    import { Layout, LayoutSider, LayoutContent } from 'ant-design-vue'

    import DetailAnchor from '@/components/detail/DetailAnchor'
    import DetailArticle from '@/components/detail/DetailArticle'

    export default {
        name: "MedicineDetailContent",
        components:{
            Layout,
            LayoutSider,
            LayoutContent,
            DetailAnchor,
            DetailArticle,
        },
        setup(){

            return {

            }
        }
    }
</script>

<style scoped>
    .detail-left {
        width: 200px;
        background: white;
        padding: 0px 0px 0px 20px;
    }
</style>