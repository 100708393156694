<template>
    <a-layout>
        <LayoutHeader>
            <div id="moolng-header">
                <MoolngHeader></MoolngHeader>
            </div>
        </LayoutHeader>
        <a-layout-content id="home-center">
            <RightBuoy2/>
            <div class="home-width">
                <router-view></router-view>
            </div>
        </a-layout-content>
        <a-layout-footer>
            <MoolngFooter></MoolngFooter>
        </a-layout-footer>
    </a-layout>
</template>

<script>
    import MoolngFooter from '@/components/MoolngFooter'
    import MoolngHeader from '@/components/MoolngHeader'
    // import RightBuoy from '@/components/plugin/RightBuoy'
    import RightBuoy2 from '@/components/plugin/RightBuoy2'
    import { useRoute, useRouter } from 'vue-router'

    import { reactive, provide, } from 'vue'
    import { get,post } from '@/utils/HttpClient'
    import { api } from '@/utils/ApiConstant'


    export default {
        name: "MainPage",
        components: {
            MoolngFooter,
            MoolngHeader,
            // RightBuoy,
            RightBuoy2,
        },
        setup() {
            console.log('to home page')
            const router = useRouter()
            const route = useRoute()
             const data = reactive({
                centerBackgroundColor:'#f0f2f5',
                navData:{
                    navMenuVOList:[],
                    navMap:{}
                },
                homeRowData:{},
                pageListResultData:{},
                currentCategoryObj:{
                    name:'',
                    routePath:'',
                    detailName:'',

                },
                currentRouteInfo:{
                    flag:false,
                    routePath:"",
                    pageNo:1,
                    pageSize:10,
                },
                moolngHeaderObj:{
                    selectValue : 'all',
                    selectCategoryValue : 'all',
                    selectOptionCategoryMap:
                        {'all':'全部', 'medicine':'中药', 'consilia':'医案'},
                    selectOptionValueuMap: {
                        consilia:{},
                        medicine:{},
                    },
                    searchKeyword:'',
                },
            })

            provide('moolngHomePageData', data)

            // 初始化导航栏
            function initNavData() {
                get('/web/getNavList').then(res=>{
                    data.navData = res.result
                })
            }


            /** 监听用户切屏次数 */
            function handleScreen() {
                // console.log('handleScreen ==>', route.path)
                if(process.env.NODE_ENV != 'production') {
                    return
                }
                // let num = 0
                window.onblur = function() {
                    console.log('handleScreen out ==>', route.path)
                    data.currentRouteInfo.routePath = route.path
                    router.push('/detail503')
                    // console.log('进入当前页面', router)
                    // console.log('进入当前页面' + num)
                    // num++
                }
                window.onfocus = function() {
                    if(data.currentRouteInfo.routePath === '') {
                        data.currentRouteInfo.routePath = '/index'
                    }
                    router.push(data.currentRouteInfo.routePath)
                    // if (num === 3) {
                    //     console.log('已离开当前页面' + num + '次，离开5次将强制交卷')
                    // }
                    // if (num > 5) {
                    //     console.log('===》已离开当前页面' + num + '次，离开5次将强制交卷')
                    // }
                }
            }
            // 暂且停用 2023-4-27 22:02:30
            // handleScreen()

            function getHomeRow() {
                post(api.getHomeRowData, {pageSize:10, pageNo:1}).then(res=>{
                    console.log('getHomeRow===> ', res)
                    data.homeRowData = res.result
                })
            }

            function init() {
                console.log('home page init===>')
                initNavData()
                getHomeRow()
            }

            init()

            return{
                data,
            }
        }
    }
</script>

<style scoped>
 #moolng-header {
     background-color: #001529;
     height:150px;
 }
</style>