// 该文件专门用于创建整个应用的路由器
import { createRouter, createWebHashHistory, createWebHistory} from 'vue-router'
//引入组件
import HomePage from '@/components/home/HomePage'

import HomeCenter from '@/components/center/HomeCenter'
import ListCenter from '@/components/center/ListCenter'
import DetailContent from '@/components/center/DetailContent'
import Detail404 from '@/components/detail/Detail404'
import Detail503 from '@/components/detail/Detail503'
import Error404 from '@/components/Error404'
import Error503 from '@/components/Error503'
import baseUrl from "@/utils/BaseURL";


//创建并暴露一个路由器
const routes = [
    // {
    //     path:'/main',
    //     component:HomePage
    // },
    {
        path:'/',
        component:HomePage,
        redirect:'/index',
        children:[
            {
                path:'/index',
                component:HomeCenter,
            },
            {
                path:'/search',
                component:ListCenter,
            },
            {
                path:'/list/:strId',
                component:ListCenter,
            },
            {
                path:'/detail/:category/:strId',
                component:DetailContent,
            },{
                path: '/detail404',
                component: Detail404
            },{
                path: '/detail503',
                component: Detail503
            }
            // {
            //     path:'/search',
            //     component:SearchPage
            // }
        ]
    }
    ,{
        path: '/:catchAll(.*)',
        redirect:'/404'
    },{
        path: '/404',
        component: Error404
    },{
        path: '/503',
        component: Error503
    }
]

// 动态配置history
function getHistory() {
    switch (process.env.NODE_ENV) {
        // case 'development':
        //     // 本地测试环境
        //     return  createWebHistory()
        case 'test':
            // 本地测试环境
            return  createWebHistory()
        case 'production':
            // 生产环境
            return  createWebHistory()
        default:
            // 4. 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
            // 开发环境
            return createWebHashHistory();
    }
}

const router = createRouter({
    history: getHistory(),
    routes, // `routes: routes` 的缩写
})

export default router;