<template>
    <!--    <Affix :offsetTop="500" :style="{ position: 'absolute', right: 0}">-->
    <!--&lt;!&ndash;        <span>凯小默的博客</span>&ndash;&gt;-->
    <!--&lt;!&ndash;        <Button>120px to affix top</Button>&ndash;&gt;-->
    <!--        <div ref="dragDiv" class="float-drag-button">-->
    <!--            <span>-->
    <!--                <a target="_blank" href="https://qm.qq.com/cgi-bin/qm/qr?k=HEPUgOSVNYQmVMRdRXUIBjSgUS455Kga&jump_from=webapi&authKey=XlwB0HlejdkG9UtOtKqO2mlKceMdksp793GTE8J2ge97MYPzd/pI8NzU9vzDS35o"><img border="0" src="//pub.idqqimg.com/wpa/images/group.png" alt="论中医技术交流群" title="论中医技术交流群"></a>-->
    <!--            </span>-->
    <!--            <span>-->
    <!--                QQ二维码进群-->
    <!--            </span>-->
    <!--        </div>-->
    <!--    </Affix>-->
    <div class="floating-panel" :class="{ 'expanded': isExpanded }">
        <div class="handle" @click="togglePanel">
                <span class="tag" :style="{ backgroundColor: isExpanded ? '#fff' : 'aqua' }">
                中医交流
                </span>
<!--            <span>关闭</span>-->
            <!--            <i class="icon" :class="{ 'fa-chevron-left': !isExpanded, 'fa-chevron-right': isExpanded }"></i>-->
        </div>
        <div class="content" :class="{ 'expanded': isExpanded }">
            <!--            <slot></slot>-->
            <span>
                <a target="_blank" href="https://qm.qq.com/cgi-bin/qm/qr?k=HEPUgOSVNYQmVMRdRXUIBjSgUS455Kga&jump_from=webapi&authKey=XlwB0HlejdkG9UtOtKqO2mlKceMdksp793GTE8J2ge97MYPzd/pI8NzU9vzDS35o"><img border="0" src="//pub.idqqimg.com/wpa/images/group.png" alt="论中医技术交流群" title="论中医技术交流群"></a>
            </span>
            <br/>
            <br/>
            <span>QQ扫描进群</span>
            <br/>
            <br/>
            <span>
                <img :src="QQ29595458" style="width: 150px; height: 150px">
            </span>

        </div>
    </div>
</template>

<script>
    // import { Affix, Button } from 'ant-design-vue'
    import { ref } from 'vue'

    export default {
        name: "RightBuoy",
        setup() {
            const isExpanded = ref(false)

            function togglePanel() {
                console.log('isExpanded', isExpanded)
                isExpanded.value = !isExpanded.value
            }

            return {
                isExpanded,
                togglePanel,
                QQ29595458: require("@/assets/QQ29595458.png"),
            }
        },
    };
</script>
<style scoped>
    .tag {
        padding-left: 5px;
        font-size: 15px;
    }

    .floating-panel {
        position: fixed;
        top: 50;
        right: 0;
        /*height: 100%;*/
        height: 0px;
        width: 20px;
        background-color: #fff;
        transition: all 0.2s ease-in-out;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        z-index: 9999;
    }

    .floating-panel.expanded {
        width: 190px;
        height: 350px;
    }

    .handle {

        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;
        cursor: pointer;
    }

    .icon {
        font-size: 20px;
        transition: transform 0.2s ease-in-out;
    }

    .icon.fa-chevron-left {
        transform: rotateY(180deg);
    }
    /*.floating-panel .expanded {*/
    /*    background-color: #fff;*/
    /*}*/
    .content {
        padding: 20px;
        /*height: 100px;*/

    }
</style>