<template>
    <BackTop/>
    <article id="detail-article">
        <header class="title">
            <h1>{{data.info.title}}</h1>
            <h1 class="tag" v-if="data.info.sourceUrl != ''">转载：<a :href="data.info.sourceUrl" target="_blank">{{data.info.source}}</a></h1>
            <h1 class="tag" v-else>来源：{{data.info.source}}</h1>
        </header>
<!--        <MoolngContextMenu >-->
            <section class="option" v-html="data.info.content" @mouseup="onSelect"></section>
<!--        </MoolngContextMenu>-->

        <div style="text-align: center; padding-top: 100px">
            <Button type="dashed" style="margin-right: 50px" @click="gotoArticle('UP')">上一篇</Button>
            <Button type="dashed" @click="gotoArticle('DOWN')">下一篇</Button>
        </div>
    </article>

</template>

<script>
    import { Tooltip, BackTop, Button, notification, message  } from 'ant-design-vue'
    import MoolngPopover from '@/components/plugin/MoolngPopover'
    import MoolngContextMenu from '@/components/plugin/MoolngContextMenu'
    import {inject, onMounted, reactive, provide} from 'vue'
    import { get } from '@/utils/HttpClient'
    import { useRoute } from 'vue-router'

    export default {
        name: "DefaultDetailContent",
        components:{
            BackTop,
            MoolngPopover,
            Tooltip,
            MoolngContextMenu,
            Button,
        },
        // emits:['click'],
        setup(props, context){
            const moolngHomePageData = inject('moolngHomePageData')

            let data = reactive({
                headStyle:{
                    'display': 'block',
                    'font-size': '2em',
                    'margin-block-start': '0.67em',
                    'margin-block-end': '0.67em',
                    'margin-inline-start': '0px',
                    'margin-inline-end': '0px',
                    'font-weight': 'bold'
                },
                info:{},
                defaultVisibleBoolean:false,
                editText:"",
                overlayStyle:"",
                x:0,
                y:0,
                currentStrId:'',
            })
            let route = useRoute()
            data.currentStrId = route.params.strId
            provide('moolngDefaultDetailData', data)

            onMounted(()=>{
                gotoArticle('OTHER')
                // let strId = route.params.strId
                // console.log('strId===>', strId)
                // get(`/web/article/view/${strId}/OTHER`).then(res=>{
                //     // get('/getMenu').then(res=>{
                //     data.info = res.result
                //     console.info(`/web/article/view/${strId}`, data.info)
                //     setDetailName(data.info.title)
                //     setHeadElement(data.info);
                // })
            })

            function getCoordinates(e) {
               const x = e.layerX
               const y = e.layerY
                data.x = x
                data.y = y
                console.log('onSelect===>',x,y)
            }

            onMounted(()=>{
                // document.addEventListener("mousemove", getCoordinates)
            })

            function onSelect() {
                console.log('onSelect')
                const text = window.getSelection().toString()
                // const text2 = window.getSelection()
                // const text3 = window.getSelection().anchorNode.baseURI
                // if(text === '') {
                //     data.defaultVisibleBoolean = false
                // }else {
                //     data.defaultVisibleBoolean = true
                // }
                data.editText = text

                console.log('onSelect===> text', text)
                // console.log('onSelect===> text2', text2)
                // console.log('onSelect===> text3',text3)

            }

            function setDetailName(detailName) {
                console.log('setDetailName', moolngHomePageData.currentCategoryObj)
                moolngHomePageData.currentCategoryObj.detailName = detailName
            }

            function setHeadElement(info) {
                setTitle(info)
                setKeywords(info)
                setDescription(info)
            }

            function setTitle(info) {
                document.title = process.env.VUE_APP_DEV_WEB_TITLE + '-' + '倪海厦、倪师医案、' + info.title + "、"  + moolngHomePageData.currentCategoryObj.name
            }

            function setKeywords(info) {
                // 获取原始
                let meta = document.getElementsByTagName('head')[0].getElementsByTagName('keywords')[0]
                // console.log('meta', meta)
                if(meta === undefined) {
                    meta = document.createElement('meta');
                    meta.name='keywords';
                    document.getElementsByTagName('head')[0].appendChild(meta);
                    meta.content = info.name + '、医案、倪海厦、倪师、' + moolngHomePageData.currentCategoryObj.name
                }
            }

            function setDescription(info) {
                // 获取原始
                let meta = document.getElementsByTagName('head')[0].getElementsByTagName('description')[0]
                if(meta === undefined) {
                    meta = document.createElement('meta');
                    meta.name='description';
                    document.getElementsByTagName('head')[0].appendChild(meta);
                    meta.content = info.visitReasonText
                }
            }

            function gotoArticle(type) {
                // let strId = route.params.strId
                let strId = data.currentStrId
                console.log('strId===>', strId)
                get(`/web/article/view/${strId}/${type}`).then(res=>{
                    if(res.result.strId === '') {
                        // openNotificationWithIcon('warning', '已是最后一篇')
                        warning('warning', '已是最后一篇')
                        return
                    }
                    // get('/getMenu').then(res=>{
                    data.info = res.result
                    data.currentStrId = res.result.strId
                    console.info(`/web/article/view/${strId}`, data.info)
                    setDetailName(data.info.title)
                    setHeadElement(data.info);
                })
            }

            const openNotificationWithIcon = (type, msg) => {
                notification[type]({
                    message: '温馨提示',
                    description: msg,
                })
            }

            const warning = (type, msg) => {
                message[type](msg);
            }

            return {
                data,
                onSelect,
                gotoArticle,
            }
        }
    }
</script>

<style scoped>
    .title {
       font-size: 30px;
       text-align: center;
    }
    .tag {
        font-size: 15px;
        text-align: right;
    }
    .option {
        font-size: 20px;
    }
    /*#moolng-tooltip {*/
    /*    position: absolute;*/
    /*    z-index: 9999;*/
    /*    top: 300px;*/
    /*    left: 400px;*/
    /*}*/
</style>