<template>
    <div :style="wrapStyles" @mouseup="onSelect" @mousedown="closePopover">
        <Popover title="Title"
                 trigger="focus"
                 v-model:visible="data.defaultVisibleBoolean"
                 :overlayStyle="data.overlayStyle"
                 :arrowPointAtCenter="data.arrowPointAtCenter"
                 :getPopupContainer="getPopupContainer">
            <template #content>
                {{data.tooltipText}}
            </template>
        </Popover>
        <slot></slot>
    </div>
<!--    <Popover title="Title" trigger="hover">-->
<!--        <template #content>-->
<!--            <p>Content</p>-->
<!--            <p>Content</p>-->
<!--        </template>-->
<!--        <Button>Hover me</Button>-->
<!--    </Popover>-->
</template>

<script>
    import { Popover, Button, } from 'ant-design-vue'
    import {onMounted, reactive, watch} from 'vue'
    export default {
        name: "MoolngPopover",
        components:{
            Popover,
            Button,
        },
        setup(){
            const wrapStyles = {
                // overflow: 'hidden',
                // position: 'relative',
                // padding: '24px',
                // border: '1px solid #e9e9e9',
            }
            const data = reactive({
                arrowPointAtCenter:true,
                defaultVisibleBoolean:false,
                tooltipText:'',
                overlayStyle:{},
                x:0,
                y:0,
                clickFlag:false,
            })

            function getCoordinates(e) {
                const x = e.layerX
                const y = e.layerY
                data.x = x
                data.y = y
                // console.log('onSelect===>',x,y)
            }

            onMounted(()=>{
                document.addEventListener("mousemove", getCoordinates)
            })

            function closePopover() {
                data.defaultVisibleBoolean = false
            }

            const getPopupContainer = trigger => {
                console.log('trigger===>', trigger.parentElement)
                return trigger.parentElement || document.body;
            }

            // watch(()=>data.clickFlag,(n, o)=>{
            //     console.log('watch clickFlag x', n, o)
            //     if(data.clickFlag) {
            //         console.log('watch clickFlag')
            //         data.clickFlag = false
            //         setTimeout(()=>{
            //             data.overlayStyle = {
            //                 top: '500px',
            //                 right:'0px',
            //                 'background-color': '#1890ff',
            //             }
            //         },5000)
            //     }
            // })

            function onSelect(val) {
                const text = window.getSelection().toString()
                const text2 = window.getSelection()
                const text3 = window.getSelection().anchorNode.baseURI
                if(text === '') {
                    data.defaultVisibleBoolean = false
                }else {
                    data.defaultVisibleBoolean = true
                }
                console.log('text', text, data.defaultVisibleBoolean)
                data.tooltipText = text
                // data.x=10
                data.clickFlag = true
                    // data.overlayStyle = {
                    //     top: data.y + 'px',
                    //     right: data.x + 'px',
                    //     'background-color': '#1890ff',
                    // }

                // const x = window.scrollX
                // const y = window.scrollY
                // const app = document.querySelector(".ant-tooltip-placement-top")
                // app.className = 'class1'
                // app.style.color = 'red'
                // console.log('onSelect app ===>', app)
                console.log('onSelect===>', text, text3, text2)
                // setTimeout(()=>{
                //     data.overlayStyle = "background-color: #1890ff; top: 0px; right:0px;"
                // }, 5000)

                // data.overlayStyle = "color:red; top:"+data.y+"px; right:" + data.x +";"
                // data.overlayStyle = "top:"+y+"px; right:" + x +";"
                // ant-tooltip-placement-top
            }
            return {
                data,
                wrapStyles,
                getPopupContainer,
                onSelect,
                closePopover,
            }
        }
    }
</script>

<style scoped>
    #id{
    background-color: #1890ff;
    }
</style>