import  Axios from 'axios'
// import baseUrl from '@/utils/BaseURL'

// import Qs from 'qs'
// let qs = Qs

// 创建 Axios实例对象
let instance =  Axios.create({
    // 基础路径
    baseURL: process.env.VUE_APP_BASE_API,
    // baseURL:"http://localhost:8081",
    timeout:5000,
    responseType: "json",
    headers: {
        //公共请求头配置，本项目请求头大多数接口是这个，所以这里可以配置一下，对于特殊接口单独配置
        // "Content-Type": "application/json; charset=UTF-8"
    }
})

// 请求拦截器
instance.interceptors.request.use(config=>{
    // console.log(config,'请求配置项');
    if(config.url !== '登录接口'){
        // token值
        config.headers.Authorization = 'token123'
    }
    return config
},error=>{
    alert('请求失败')
    return Promise.reject(error)
})

// 响应拦截器
instance.interceptors.response.use(response=>{
    let res = response.data
    return res
},error=>{
    return Promise.reject(error)
})

// 封装get方法并导出
export function get(url,params){
    return instance.get(url,{
        params,
        // headers:{"Content-Type": "application/html; charset=UTF-8"}
    })
}
// 封装postJSON方法 (发送json格式数据)
export function postJSON(url,data){
    return instance.post(url,data)
}
// 封装post方法 (发送表单格式数据)
export function post(url,data){
    return instance.post(
        url,
        // qs.stringify(data),
        data,
        {
        headers:{"Content-Type": "application/json; charset=UTF-8"}
    })
}

// 导出 Axios实例
export default instance