import { createApp } from 'vue'
import App from './App.vue'
import 'ant-design-vue/dist/antd.css'

// import VRouter from 'vue-router'
import router from './router/index'
// 右键菜单，暂停（等功能完善在开发） 2023-5-3 12:39:13
// import contextmenu from "v-contextmenu";
// import { useRouter } from 'vue-router'
// const mrouter = useRouter()
console.log(process.env)
createApp(App)
    .use(router)
    // .use(contextmenu)
    .mount('#app')

// //禁止F12
// document.addEventListener("keydown", function (e) {
//     // console.log('addEventListener ===>', e)
//     if (e.key == "F12") {
//         e.preventDefault(); // 如果按下键F12,阻止事件
//     }else if(e.key == "Control" || e.key == "s") {
//         e.preventDefault(); // 如果按下键F12,阻止事件
//     }
// });
//
// document.onselectstart = new Function("event.returnValue=false");
//
// //按键触发
// document.onkeydown = function(){
//     //禁止ctrl+u
//     if (event.ctrlKey && window.event.keyCode==85){
//         return false;
//     }
// }
//
// //屏蔽右键菜单
// document.oncontextmenu = function (event){
//     if(window.event){
//         event = window.event;
//     }try{
//         var the = event.srcElement;
//         if (!((the.tagName == "INPUT" && the.type.toLowerCase() == "text") || the.tagName == "TEXTAREA")){
//             return false;
//         }
//         return true;
//     }catch (e){
//         return false;
//     }
// };