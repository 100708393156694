<template>
    <Row class="home-width head-row">
        <Col :span="12">
            <a  @click.prevent="goHome()"><div class="logo"></div></a>
        </Col>
        <Col :span="12">
            <InputGroup>
                <InputSearch
                        style="padding-top: 20px; "
                        v-model:value="moolngHomePageData.moolngHeaderObj.searchKeyword"
                        placeholder="请输入"
                        enter-button="搜索"
                        size="large"
                        @search="onSearch"
                        allow-clear
                >
                    <template #addonBefore>
                        <Select v-model:value="moolngHomePageData.moolngHeaderObj.selectCategoryValue" style="width:150px" @change="setCategoryVal">
                            <SelectOption :value="key" v-for="(val, key) in moolngHomePageData.moolngHeaderObj.selectOptionCategoryMap">{{val}}</SelectOption>
                            <!--                                <SelectOption value="name">姓名</SelectOption>-->
                            <!--                                <SelectOption value="Sign Up2">Sign Up2</SelectOption>-->
                        </Select>
                        <!--                            mode="multiple" max-tag-count="1" max-tag-text-length="-1"-->
                        <Select v-model:value="moolngHomePageData.moolngHeaderObj.selectValue" style="width:100px" v-if="moolngHomePageData.moolngHeaderObj.selectCategoryValue != 'all'" >
                            <SelectOption :value="key" v-for="(val, key) in moolngHomePageData.moolngHeaderObj.selectOptionValueuMap[moolngHomePageData.moolngHeaderObj.selectCategoryValue]">{{val}}</SelectOption>
                            <!--                                <SelectOption value="all">全部</SelectOption>-->
                            <!--                                <SelectOption value="name">姓名</SelectOption>-->
                            <!--                                <SelectOption value="Sign Up2">Sign Up2</SelectOption>-->
                        </Select>
                    </template>
                </InputSearch>

                <!--                    <Input>-->
                <!--                        <template #addonBefore>-->
                <!--                            <Select v-model:value="value15">-->
                <!--                                <SelectOption value="Sign Up1">Sign Up1</SelectOption>-->
                <!--                                <SelectOption value="Sign Up2">Sign Up2</SelectOption>-->
                <!--                            </Select>-->
                <!--                        </template>-->
                <!--                    </Input>-->
                <!--                    <Select v-model:value="value15">-->
                <!--                        <SelectOption value="Sign Up">Sign Up</SelectOption>-->
                <!--                        <SelectOption value="Sign In">Sign In</SelectOption>-->
                <!--                    </Select>-->
                <!--                    <AutoComplete-->
                <!--                            v-model:value="value16"-->
                <!--                            :options="[{ value: 'text 1' }, { value: 'text 2' }]"-->
                <!--                            style="width: 200px"-->
                <!--                            placeholder="Email"-->
                <!--                    />-->
                <!--                    <Search v-model:value="value15">-->
                <!--                        <SelectOption value="Sign Up">Sign Up</SelectOption>-->
                <!--                        <SelectOption value="Sign In">Sign In</SelectOption>-->
                <!--                    </Search>-->
                <!--                    <InputSearch-->
                <!--                            style="padding-top: 20px"-->
                <!--                            v-model:value="data.searchKeyword"-->
                <!--                            placeholder="请输入"-->
                <!--                            enter-button="搜索"-->
                <!--                            size="large"-->
                <!--                            @search="onSearch"-->
                <!--                            allow-clear-->
                <!--                    />-->
            </InputGroup>


            <!--                <InputSearch-->
            <!--                        style="padding-top: 20px"-->
            <!--                        v-model:value="data.searchKeyword"-->
            <!--                        placeholder="请输入"-->
            <!--                        enter-button="搜索"-->
            <!--                        size="large"-->
            <!--                        @search="onSearch"-->
            <!--                        allow-clear-->
            <!--                >-->
            <!--                    <template #addonAfter>-->
            <!--                        <a-select v-model:value="value4" style="width: 80px">-->
            <!--                            <a-select-option value=".com">.com</a-select-option>-->
            <!--                            <a-select-option value=".jp">.jp</a-select-option>-->
            <!--                            <a-select-option value=".cn">.cn</a-select-option>-->
            <!--                            <a-select-option value=".org">.org</a-select-option>-->
            <!--                        </a-select>-->
            <!--                    </template>-->
            <!--                </InputSearch>-->
        </Col>
    </Row>
    <Row class="head-row">
        <Col :span="24">
            <div class="home-width">
                <MoolngHeaderMenu></MoolngHeaderMenu>
            </div>
        </Col>
    </Row>
</template>

<script>
    import MoolngHeaderMenu from '@/components/MoolngHeaderMenu'
    import RightBuoy from '@/components/plugin/RightBuoy'
    import { api } from '@/utils/ApiConstant'
    import { Row, Col, LayoutHeader, InputSearch, InputGroup, Input, Select, SelectOption, AutoComplete } from 'ant-design-vue'
    import { get, post } from '@/utils/HttpClient'
    import { useRouter } from "vue-router";
    import { provide, reactive, inject } from 'vue'



    export default {
        name: "MoolngHeader",
        components:{
            MoolngHeaderMenu,
            LayoutHeader,
            Row,
            Col,
            InputSearch,
            RightBuoy,
            InputGroup,
            Input,
            AutoComplete,
            Select,
            SelectOption,
        },
        setup() {
            const router = useRouter()

            const data = reactive({
                // selectValue : 'all',
                // selectCategoryValue : 'all',
                // selectOptionCategoryMap:
                //     {'all':'全部', 'medicine':'中药', 'consilia':'医案'},
                // selectOptionValueuMap: {
                //     consilia:{},
                //     medicine:{},
                // },
                // searchKeyword:'',
            })

            // provide('moolngHeaderData', data)
            const moolngHomePageData = inject('moolngHomePageData')

            const onSearch = () => {
                search()
            }

            // function updateCategoryName(categoryName) {
            //     data.categoryName = categoryName
            // }
            //
            // function updateRowData(obj) {
            //     data.searchResult = obj
            // }

            function setCategoryVal() {
                moolngHomePageData.moolngHeaderObj.selectValue = 'all'
                console.log('getCategoryVal', moolngHomePageData.moolngHeaderObj.selectCategoryValue)
                getOption()
            }

            function goHome() {
                router.push('/index')
            }

            function search() {
                if(moolngHomePageData.moolngHeaderObj.searchKeyword === '') {
                    return
                }
                let searchObj = {'searchKeyword': moolngHomePageData.moolngHeaderObj.searchKeyword, 'searchFieldList': [moolngHomePageData.moolngHeaderObj.selectValue], 'category':moolngHomePageData.moolngHeaderObj.selectCategoryValue}
                post(api.search, searchObj).then(res=>{
                    // let thisData = res.result
                    if(res.status === 200) {
                        router.push('/search')
                        moolngHomePageData.pageListResultData = res.result
                        // data.searchResult = thisData
                    }
                })
            }
            
            function getOption() {
                const categoryVal =  moolngHomePageData.moolngHeaderObj.selectCategoryValue
                if(categoryVal === 'all') {
                    return;
                }
                const selectOptionValue =  moolngHomePageData.moolngHeaderObj.selectOptionValueuMap[categoryVal]
                if(selectOptionValue.all != undefined) {
                    return;
                }
                get(api.getOption + categoryVal).then(res=>{
                    let thisData = res.result
                    if(res.status === 200) {
                        moolngHomePageData.moolngHeaderObj.selectOptionValueuMap[categoryVal] = thisData
                    }
                })
            }

            return {
                moolngHomePageData,
                onSearch,
                data,
                goHome,
                setCategoryVal,
            }
        }
    }
</script>

<style scoped>

    .ant-layout {
        background-color: #f0f2f5;
        /*background: white;*/
    }

    .logo {
        width: 180px;
        height: 55px;
        background-image: url("../assets/logo.png");
        background-repeat:no-repeat;
        background-size: 180px 55px;
        margin-top: 5px;
    }

    /*.logo {*/
    /*    float: left;*/
    /*    width: 200px;*/
    /*    height: 60px;*/
    /*    !*margin: 16px 24px 16px 0;*!*/
    /*    margin: 4px 0px 0px 0px;*/
    /*    background: rgba(255, 255, 255, 0.3);*/
    /*    color: white;*/
    /*    font-size: 40px;*/
    /*    line-height: 60px;*/
    /*    text-align: center;*/
    /*    !*letter-spacing:25px;*!*/
    /*}*/
    .head-row {
        padding-top: 10px;
    }
</style>