<template>
    <div class="error404">
        <img :src="error404png">
    </div>
</template>

<script>
    import {inject, onMounted} from 'vue'
    export default {
        name: "Detail404",
        setup(){
            // const homePageData = inject('moolngHomePageData')
            // onMounted(()=>{
            //     homePageData.centerBackgroundColor = '#000'
            //     console.log('Detail404 homePageData', homePageData)
            // })
            return {
                error404png: require("@/assets/404.png"),
            }
        }
    }
</script>

<style scoped>
    .error404 {
        text-align: center;
        font-size: 40px;
        height: 500px;
        background-color: #FBFBFB;
        /*height: 100%;*/
        /*width: 100%;*/
        /*position: fixed;*/
        /*display: none;*/
    }
    .error404 img {
        margin-top: 100px;
        /*display: block;*/
        /*position: absolute;*/
        top: 50px;
        left: 50%;
    }
</style>