<template>
    <SubMenu :title="menuInfo.name">
<!--        <template #title>{{ menuInfo.name }}</template>-->
        <template v-for="item in menuInfo.children">
            <MenuItem v-if="item.children.length == 0" :key="item.strId" @click="menuClick2(item)">{{item.name}}</MenuItem>
            <SubMenu v-else :title="item.name">
                <MenuItem v-for="item2 in item.children" :key="item2.strId" @click="menuClick2(item2)">{{item2.name}}</MenuItem>
            </SubMenu>
        </template>
    </SubMenu>
</template>

<script>
    import { MenuItem, SubMenu } from 'ant-design-vue'

    export default {
        name: "MenuChildren",
        props: {
            menuInfo: {
                type: Object,
                default: () => ({}),
            },
        },
        emits:['childrenMenuClick'],
        components:{
            SubMenu,
            MenuItem,
        },
        setup(props, content) {

            function menuClick2(item) {
                content.emit('childrenMenuClick', item)
            }

            return {
                menuClick2,
            }
        }
    }
</script>

<style scoped>

</style>