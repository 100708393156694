<template>
    <Breadcrumb style="margin: 16px 0">
        <BreadcrumbItem>首页</BreadcrumbItem>
        <BreadcrumbItem v-if="moolngHomePageData.currentCategoryObj.name != ''"><router-link :to="moolngHomePageData.currentCategoryObj.routePath">{{moolngHomePageData.currentCategoryObj.name}}</router-link></BreadcrumbItem><!--        <BreadcrumbItem>蟾酥 = {{resultData.searchKeyword}}</BreadcrumbItem>-->
    </Breadcrumb>
    <List item-layout="horizontal" :data-source="data.dataItem" :pagination="{...pagination, ...pageInfo}" style="padding: 0px 0px 25px 0px; background: #fff">
        <template #renderItem="{ item }">
            <ListItem>
                <ListItemMeta>
                    <template #description>
                        <div v-html="item.description"/>
                    </template>
                    <template #title>
                        <span v-if="item.topFlag && item.topFlag === 1" class="title moolng-top">置顶</span>
                        <a @click.prevent="gotoDetail(item.strId, item.category)" class="title" v-html="`${ item.title }`"></a>
                    </template>
                </ListItemMeta>
            </ListItem>
        </template>
    </List>
</template>

<script>
    import {reactive, inject, watch, onMounted } from 'vue'
    import { List, ListItem, ListItemMeta, Breadcrumb, BreadcrumbItem, Avatar } from 'ant-design-vue'
    import { post } from '@/utils/HttpClient'
    import { useRouter, useRoute } from 'vue-router'
    import { constant } from '@/common/Common'
    import { api } from '@/utils/ApiConstant'


    export default {
        name: "ListCenter",
        components:{
            List,
            ListItem,
            ListItemMeta,
            Breadcrumb,
            BreadcrumbItem,
        },
        setup(props, content){
            const moolngHomePageData = inject('moolngHomePageData')
            console.log('list center inject moolngHomePageData ==>', moolngHomePageData)

            let router = useRouter()
            let route = useRoute()

            let data = reactive({
                dataItem:[],
                categoryName:'',
            })

            // 传递分页参数
            const pagination = {
                // pageSize: 5,
                // total: 100,
                // current: (ref(1)),
                //修改分页样式，设置为居中
                style: {
                    'text-align': "center",
                    // position: "relative",
                    // right: "50%",
                    // transform: "translate(50%)",
                },
                onChange:(pageNo, pageSize)=>{
                    getRowData(pageNo, pageSize)
                },
                showSizeChange:()=>{
                    console.log('showSizeChange===>')
                }
            }

            const pageInfo = reactive({
                "current": 1,
                "pageSize": 10,
                "total": 0,
            })

            function gotoDetail(strId, category) {
                const routerLink = constant.routerLinkMap[category] + strId

                // router.push(routerLink)
                let routeData = router.resolve(routerLink)
                window.open(routeData.href, '_blank')
            }

            watch(()=>moolngHomePageData.pageListResultData,(n, o)=>{
                console.log('watch moolngHomePageData.pageListResultData ===》', moolngHomePageData.pageListResultData)
                const resultData = moolngHomePageData.pageListResultData
                let dataList = resultData.data
                if(dataList) {
                    data. dataItem= dataList
                    setPageInfo(resultData)
                }
            })

            watch(()=>moolngHomePageData.navData,(n, o)=>{
                console.log('watch moolngHomePageData.navData ===》', moolngHomePageData.navData)
                getRowData( null, null)

            })

            function getRowData(pageNo, pageSize) {
                const path = route.path
                let strId = route.params.strId
                let dataPath = ''
                let requestBody = {}
                if(path === '/search') {
                    dataPath = api.search
                    requestBody = {pageSize:pageSize, pageNo:pageNo, 'searchKeyword': moolngHomePageData.moolngHeaderObj.searchKeyword, 'searchFieldList': [moolngHomePageData.moolngHeaderObj.selectValue], 'category':moolngHomePageData.moolngHeaderObj.selectCategoryValue}
                }else {
                    dataPath = moolngHomePageData.navData.navMap[strId].dataPath
                    requestBody = {pageSize:pageSize, pageNo:pageNo, categoryStrId:strId}
                }
                doGetRowData(dataPath, requestBody)
            }

            function doGetRowData(dataPath, requestBody) {
                post(dataPath, requestBody).then(res=>{
                    console.log(dataPath, res)
                    // data.dataItem = res.result.data
                    moolngHomePageData.pageListResultData = res.result
                    setPageInfo(res.result)
                })
            }

            function setPageInfo(obj) {
                pageInfo.total = obj.total
                pageInfo.pageSize = obj.pageSize
                pageInfo.current = obj.pageNo
                setCurrentCategoryInfo(obj)
            }

            function setCurrentCategoryInfo(obj) {
                // 暂且停用 2023-4-27 22:03:40
                // moolngHomePageData.currentRouteInfo.pageNo = obj.pageNo
                // moolngHomePageData.currentRouteInfo.pageSize = obj.pageSize
            }

            onMounted(()=>{
                console.log('onMounted', route.path)
                if(route.path != '/search') {
                    return
                }
                const resultData = moolngHomePageData.pageListResultData
                let dataList = resultData.data
                if(dataList) {
                    data.dataItem= dataList
                    setPageInfo(resultData)
                }
            })

            return {
                data,
                pagination,
                // resultData,
                gotoDetail,
                pageInfo,
                moolngHomePageData,
                logoPng: require("@/assets/logo.png"),
            }
        }
    }
</script>

<style scoped>
    .title {
        font-size: 20px;
    }
    .moolng-top {
        color: red;
        padding-right: 50px;
        font-weight: bold;
    }
</style>