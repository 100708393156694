<template>
<!--        <BackTop/>-->
        <article id="detail-article">
            <section class="option">
                <Descriptions bordered :column="{ md: 1}">
                    <template #title>
<!--                        <h1 style="font-size: 40px">-->
<!--                            -->
<!--                        </h1>-->
                        <span style="font-size: 30px">倪醫師病案紀錄</span><br/>初診日期：{{data.info.firstVisitDate}}
                    </template>
                    <DescriptionsItem label="姓名">{{data.info.name}}</DescriptionsItem>
                    <DescriptionsItem label="性别">{{data.info.sex}}</DescriptionsItem>
                    <DescriptionsItem label="年龄">{{data.info.age}}</DescriptionsItem>
                    <DescriptionsItem label="体型">{{data.info.somatotype}}</DescriptionsItem>
                    <DescriptionsItem label="来诊日期">{{data.info.visitDate}}</DescriptionsItem>
                    <DescriptionsItem label="来诊原因：">
                        <div v-html="data.info.visitReason"></div>
                    </DescriptionsItem>
                    <DescriptionsItem label="问诊：">
                        <div v-html="data.info.inquiry"></div>
                    </DescriptionsItem>
                    <DescriptionsItem label="望诊：">
                        <div v-html="data.info.visit"></div>
                    </DescriptionsItem>
                    <DescriptionsItem label="脉诊：">
                        <div v-html="data.info.sphygmus"></div>
                    </DescriptionsItem>
                    <DescriptionsItem label="舌诊：">
                        <div v-html="data.info.tongueVisit"></div>
                    </DescriptionsItem>
                    <DescriptionsItem label="耳诊：">
                        <div v-html="data.info.earVisit"></div>
                    </DescriptionsItem>
                    <DescriptionsItem label="诊断：">
                        <div v-html="data.info.diagnose"></div>
                    </DescriptionsItem>
                    <DescriptionsItem label="特殊诊断：">
                        <div v-html="data.info.specialVisit"></div>
                    </DescriptionsItem>
                    <DescriptionsItem label="针灸处方：">
                        <div v-html="data.info.acupuncturePrescription"></div>
                    </DescriptionsItem>
                    <DescriptionsItem label="中药处方：">
                        <div v-html="data.info.medicinePrescription"></div>
                    </DescriptionsItem>
                    <DescriptionsItem label="解说：">
                        <div v-html="data.info.narrate"></div>
                    </DescriptionsItem>
                    <DescriptionsItem label="备注：">
                        <div v-html="data.info.remarks"></div>
                    </DescriptionsItem>
                </Descriptions>
            </section>



        </article>
</template>

<script>
    import { Card,Row,Col,SkeletonImage, Descriptions, DescriptionsItem } from 'ant-design-vue'
    import {onMounted, reactive, inject} from 'vue'
    import { get } from '@/utils/HttpClient'
    import { useRoute } from 'vue-router'

    export default {
        name: "DetailArticle",
        components:{
            Card,
            Row,
            Col,
            SkeletonImage,
            Descriptions,
            DescriptionsItem,
            // BackTop
        },
        // emits:['click'],
        setup(props, context){
            const moolngHomePageData = inject('moolngHomePageData')

            let data = reactive({
                headStyle:{
                    'display': 'block',
                    'font-size': '2em',
                    'margin-block-start': '0.67em',
                    'margin-block-end': '0.67em',
                    'margin-inline-start': '0px',
                    'margin-inline-end': '0px',
                    'font-weight': 'bold'
                },
                info:{}
            })
            let route = useRoute()

            onMounted(()=>{
                let strId = route.params.strId
                get(`/web/consilia/view/${strId}`).then(res=>{
                    data.info = res.result
                    console.info(`/web/consilia/view/${strId}`, data.info)
                    // setDetailName(data.info.name)
                    // setHeadElement(data.info);
                })
            })

            function setDetailName(detailName) {
                moolngHomePageData.currentCategoryObj.detailName = detailName
            }

            function setHeadElement(info) {
                setTitle(info)
                setKeywords(info)
                setDescription(info)
            }

            function setTitle(info) {
                document.title = process.env.VUE_APP_DEV_WEB_TITLE + '-' + '倪海厦、倪师医案、倪海厦医案、' + info.name
            }

            function setKeywords(info) {
                // 获取原始
                let meta = document.getElementsByTagName('head')[0].getElementsByTagName('keywords')[0]
                if(meta === undefined) {
                    meta = document.createElement('meta');
                    meta.name='keywords';
                    document.getElementsByTagName('head')[0].appendChild(meta);
                    meta.content = info.name + '、医案、倪海厦、倪师、倪海厦医案'
                }
            }

            function setDescription(info) {
                // 获取原始
                let meta = document.getElementsByTagName('head')[0].getElementsByTagName('description')[0]
                if(meta === undefined) {
                    meta = document.createElement('meta');
                    meta.name='description';
                    document.getElementsByTagName('head')[0].appendChild(meta);
                    meta.content = info.visitReasonText
                }
            }

            return {
                data,
            }
        }
    }
</script>

<style scoped>
    /*#detail-article {*/
    /*    !*padding: 0 20px 0 0;*!*/
    /*    min-height: 280px;*/
    /*}*/
    /*.ant-skeleton-element .ant-skeleton-image {*/
    /*    width:200px*/
    /*}*/
    /*.option {*/
    /*    !*border:1px solid #000;*!*/
    /*    !*padding: 0 0 0 20px;*!*/
    /*}*/
    /*.option div {*/
    /*    font-size: 20px;*/
    /*    line-height: 50px;*/
    /*    border:1px solid #000;*/
    /*    padding: 0 0 0 20px;*/
    /*}*/
</style>